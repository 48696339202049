<template>
  <base-modal
    hide-footer
    v-model:open="isOpen"
  >
    <template v-slot:button>
      <connect-button
        :accounts="[{
          image: image,
          connected: hasSelectedPage
        }]"
      />
    </template>

    <div class="card-header">
      <div class="card-title">
        {{ $t('page.settings.social_accounts.sentence.connect_social_account-dynamic', { source: 'Linkedin' }) }}
      </div>
      <div class="flex justify-start items-center">
        <button @click.prevent="closeModal" class="px-2">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <div v-if="isConnected">
        <div v-if="hasSelectedPage">
          <div class="font-semibold mt-8 mb-8 text-center">
            {{ $t('page.settings.social_accounts.sentence.you_are_connected_in-dynamic', { source: 'Linkedin' }) }}
          </div>

          <div class="flex flex-col items-center my-12">
            <img
              class="w-16 h-16 mb-2 rounded-full"
              :src="selectedPage?.picture"
              @error="imageFallback"
            >
            <div class="font-semibold text-center">
              {{ selectedPage?.name }}
            </div>
            
            <a
              class="mt-4 text-center"
              href="javascript:void(0)"
              title="Conectar outra página"
              @click.prevent="unselectPage"
            >
              {{ $t('page.settings.social_accounts.sentence.change_social_account_profile_or_company') }}
            </a>
          </div>
        </div>

        <div v-else-if="hasPages">
          <div class="mt-4 mb-8">
            {{ $t('page.settings.social_accounts.sentence.select_new_profile_or_company') }}
          </div>

          <pages-list
            :pagesList="pagesList"
            :sources="['Linkedin']"
            v-model="selectedPageId"
          />
        </div>
      </div>
      <div v-else>
        <div class="mt-8 mb-12">
          {{ $t('page.settings.social_accounts.sentence.connect_social_account_message-dynamic', { source: 'Linkedin' }) }}
        </div>

        <div class="text-xs my-8">
          {{ $t('page.settings.social_accounts.sentence.linkedin_only_profile_alert') }}
        </div>
      </div>
    </div>
    <div
      class="card-footer flex justify-between items-center space-x-2"
    >
      <div class="flex justify-start items-center space-x-2">
        <disconnect-account-button source="linkedin"
          v-if="isConnected"
          @disconnected="restart"
        />
      </div>
      <div class="flex justify-end items-center space-x-2">
        <connect-account-button source="linkedin" v-if="!isConnected"/>
        <button
          v-if="isConnected && !hasSelectedPage"
          class="btn btn-primary"
          :disabled="!selectedPageId"
          @click.prevent="handleCompleteAction"
        >
        {{ $t('page.settings.social_accounts.sentence.connect_social_account-dynamic', { source: 'Linkedin' }) }}

          <i class="fas fa-spinner fa-spin !mr-0 ml-4" v-if="isConnectingAccount"></i>
          <i class="fas fa-arrow-right !mr-0 ml-4" v-else></i>
        </button>
      </div>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, unref } from 'vue'
import { firestore } from '@/plugins/firebase'
import { useProfile } from '@/composables/profile/useProfile'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '../base/BaseModal.vue'
import ConnectButton from './ConnectButton.vue'
import { useI18n } from 'vue-i18n'
import { useAxios } from '@/composables/useAxios'
import PagesList from './PagesList.vue'
import ConnectAccountButton from './ConnectAccountButton.vue'
import DisconnectAccountButton from './DisconnectAccountButton.vue'

interface PageProps {
  id: string
  name: string
  picture: any
  source: string
  username?: string
  link?: string
}

export default defineComponent({
  components: {
    BaseModal,
    ConnectButton,
    PagesList,
    ConnectAccountButton,
    DisconnectAccountButton
},
  setup () {
    const selectedPageId = ref<string>('')
    const hasPages = computed(() => pagesList.value.length > 0)
    const isConnectingAccount = ref(false)

    const { profile } = useProfile()

    const { t } = useI18n()

    const selectedPage = ref<any>()

    const pagesList = ref<PageProps[]>([])

    const image = computed(() => require('@/assets/img/linkedin-logo-full.png'))

    const {
      isActive: isOpen,
      setInactive: closeModal,
    } = useToggle(false)

    const {
      isActive: isConnected,
      setActive: setConnected,
      setInactive: setDisconnected,
    } = useToggle(false)

    const hasSelectedPage = computed(() => selectedPage.value !== undefined)

    const selectedPageInfo = computed(() => {
      return pagesList.value.find((p) => p.id === selectedPage.value?.id)
    })

    const handleCompleteAction = async () => {
      if (selectedPageId.value) {
        isConnectingAccount.value = true
        const { httpFunctions } = await useAxios()
        await httpFunctions.put(`/socialLogin-requests/select-page`, {
          source: 'linkedin',
          profileId: profile.value.id,
          pageId: selectedPageId.value
        })
      }

      isConnectingAccount.value = false
      closeModal()
    }

    const disconnect = async () => {
      const { httpFunctions } = await useAxios()
      await httpFunctions.put(`/socialLogin-requests/disconnect/${profile.value.id}/linkedin`)
    }

    const selectPage = async (page: PageProps) => {
      const { httpFunctions } = await useAxios()
      await httpFunctions.put(`/socialLogin-requests/select-page`, {
        source: 'linkedin',
        profileId: profile.value.id,
        pageId: page.link
      })
    }

    const restart = () => {
      setDisconnected()
      selectedPage.value = undefined

      selectedPageId.value = ''
    }

    const unselectPage = async () => {
      const { httpFunctions } = await useAxios()
      await httpFunctions.put(`/socialLogin-requests/unselect-page`, {
        source: 'linkedin',
        profileId: profile.value.id
      })
    }

    const imageFallback = async (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      element.src = require('@/assets/profile/manyrobot-profile.svg')
    }

    onMounted(async () => {
      firestore.collection('socialAccounts')
        .doc(unref(profile).id)
        .onSnapshot((accountsDoc) => {
          selectedPage.value = undefined

          if (accountsDoc.exists) {
            const accountsData = accountsDoc.data()

            if (accountsData) {
              const { linkedin } = accountsData

              if (linkedin) {
                setConnected()

                if (linkedin.pages) {
                  const list = [...linkedin.pages]
                    .sort((a, b) => a.name > b.name ? 1 : -1)
                    .map((page: any) => {
                      const picture = ('logo' in page) ? page.logo : null
                      return {
                        id: page.pageURN,
                        picture,
                        name: page.name,
                        source: 'linkedin'
                      }
                    })                    
                  pagesList.value = list
                }

                pagesList.value.unshift({
                  id: linkedin.urn,
                  name: t('sentence.my_profile'),
                  picture: 'no-image',
                  source: 'linkedin'
                })

                if (linkedin.selectedPage) {
                  selectedPage.value = {
                    id: linkedin.selectedPage.urn,
                    name: linkedin.selectedPage.name,
                    picture: linkedin.selectedPage.logo,
                    source: 'linkedin'
                  }
                }

                return
              }
            }
          }

          setDisconnected()
          selectedPage.value = undefined
        })
    })

    return {
      selectedPageId,
      hasPages,
      isConnectingAccount,
      closeModal,
      pagesList,
      selectedPageInfo,
      hasSelectedPage,
      selectedPage,
      unselectPage,
      image,
      isConnected,
      isOpen,
      disconnect,
      selectPage,
      imageFallback,
      handleCompleteAction,
      restart
    }
  },
})
</script>
