
import { computed, defineComponent, onMounted, ref, unref } from 'vue'
import { firestore } from '@/plugins/firebase'
import { useProfile } from '@/composables/profile/useProfile'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '../base/BaseModal.vue'
import ConnectButton from './ConnectButton.vue'
import { useI18n } from 'vue-i18n'
import { useAxios } from '@/composables/useAxios'
import PagesList from './PagesList.vue'
import ConnectAccountButton from './ConnectAccountButton.vue'
import DisconnectAccountButton from './DisconnectAccountButton.vue'

interface PageProps {
  id: string
  name: string
  picture: any
  source: string
  username?: string
  link?: string
}

export default defineComponent({
  components: {
    BaseModal,
    ConnectButton,
    PagesList,
    ConnectAccountButton,
    DisconnectAccountButton
},
  setup () {
    const selectedPageId = ref<string>('')
    const hasPages = computed(() => pagesList.value.length > 0)
    const isConnectingAccount = ref(false)

    const { profile } = useProfile()

    const { t } = useI18n()

    const selectedPage = ref<any>()

    const pagesList = ref<PageProps[]>([])

    const image = computed(() => require('@/assets/img/linkedin-logo-full.png'))

    const {
      isActive: isOpen,
      setInactive: closeModal,
    } = useToggle(false)

    const {
      isActive: isConnected,
      setActive: setConnected,
      setInactive: setDisconnected,
    } = useToggle(false)

    const hasSelectedPage = computed(() => selectedPage.value !== undefined)

    const selectedPageInfo = computed(() => {
      return pagesList.value.find((p) => p.id === selectedPage.value?.id)
    })

    const handleCompleteAction = async () => {
      if (selectedPageId.value) {
        isConnectingAccount.value = true
        const { httpFunctions } = await useAxios()
        await httpFunctions.put(`/socialLogin-requests/select-page`, {
          source: 'linkedin',
          profileId: profile.value.id,
          pageId: selectedPageId.value
        })
      }

      isConnectingAccount.value = false
      closeModal()
    }

    const disconnect = async () => {
      const { httpFunctions } = await useAxios()
      await httpFunctions.put(`/socialLogin-requests/disconnect/${profile.value.id}/linkedin`)
    }

    const selectPage = async (page: PageProps) => {
      const { httpFunctions } = await useAxios()
      await httpFunctions.put(`/socialLogin-requests/select-page`, {
        source: 'linkedin',
        profileId: profile.value.id,
        pageId: page.link
      })
    }

    const restart = () => {
      setDisconnected()
      selectedPage.value = undefined

      selectedPageId.value = ''
    }

    const unselectPage = async () => {
      const { httpFunctions } = await useAxios()
      await httpFunctions.put(`/socialLogin-requests/unselect-page`, {
        source: 'linkedin',
        profileId: profile.value.id
      })
    }

    const imageFallback = async (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      element.src = require('@/assets/profile/manyrobot-profile.svg')
    }

    onMounted(async () => {
      firestore.collection('socialAccounts')
        .doc(unref(profile).id)
        .onSnapshot((accountsDoc) => {
          selectedPage.value = undefined

          if (accountsDoc.exists) {
            const accountsData = accountsDoc.data()

            if (accountsData) {
              const { linkedin } = accountsData

              if (linkedin) {
                setConnected()

                if (linkedin.pages) {
                  const list = [...linkedin.pages]
                    .sort((a, b) => a.name > b.name ? 1 : -1)
                    .map((page: any) => {
                      const picture = ('logo' in page) ? page.logo : null
                      return {
                        id: page.pageURN,
                        picture,
                        name: page.name,
                        source: 'linkedin'
                      }
                    })                    
                  pagesList.value = list
                }

                pagesList.value.unshift({
                  id: linkedin.urn,
                  name: t('sentence.my_profile'),
                  picture: 'no-image',
                  source: 'linkedin'
                })

                if (linkedin.selectedPage) {
                  selectedPage.value = {
                    id: linkedin.selectedPage.urn,
                    name: linkedin.selectedPage.name,
                    picture: linkedin.selectedPage.logo,
                    source: 'linkedin'
                  }
                }

                return
              }
            }
          }

          setDisconnected()
          selectedPage.value = undefined
        })
    })

    return {
      selectedPageId,
      hasPages,
      isConnectingAccount,
      closeModal,
      pagesList,
      selectedPageInfo,
      hasSelectedPage,
      selectedPage,
      unselectPage,
      image,
      isConnected,
      isOpen,
      disconnect,
      selectPage,
      imageFallback,
      handleCompleteAction,
      restart
    }
  },
})
